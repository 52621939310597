@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;700&display=swap');

.Roboto {
  font-family: 'Roboto Mono', monospace;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

body {
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  background: linear-gradient(#2EF297, #0C4028);
}

.img-wrapper {
  background: #2EF297;
}

.img-wrapper img {
  mix-blend-mode: darken;
  filter: grayscale(100%);
}

.post-article{
  transition: 0.25s;
}

.post-article:hover{
  transform: scale(1.1);
  transition: 0.25s;
}

.post-article:hover img {
  mix-blend-mode: normal;
  filter: none;
  transition: 1s;
}

.post-article:hover h3 {
  color: white;
}

.project-article{
  transition: 0.25s;
}

.project-article:hover{
  transform: scale(1.1);
  transition: 0.25s;
}